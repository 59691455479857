import React from "react"
import "./TMHFooterStyles.scss"

export default function TMHFooter({contact = true, mobile=true}) {
    return (
        <footer className="wrapper-footer">
            <a className="footer-item" href={"/#/terms-of-use" + (mobile ? "": "/extension")} target="_blank" rel="noreferrer">
                {mobile ? "Mobile app" : "Extension"} Terms of use
            </a>
            <a className="footer-item" href="/#/privacy-policy" target="_blank" rel="noreferrer">
                Privacy policy
            </a>
            { contact && <a className="footer-item" href="mailto:contact@mymovieremote.com">
                Contact
            </a> }
        </footer>
    )
}