import React from "react"
import "./TMHHeaderStyles.scss"
import LogoImg from "./logo.png"

export default function TMHHeader() {
    return (
        <a href="/" className="tmh-header">
            <label className="header-title">Roku <b>Movie Remote</b></label>
            <img className="header-logo" src={LogoImg} alt="Roku Remote logo" />
        </a>
    )
}