import { HashRouter, Route, Routes } from "react-router-dom"
import LandingPage from "./pages/LandingPage/LandingPage"
import TermsOfUsePage from "./pages/TermsOfUsePage/TermsOfUsePage"
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage"
import ExtensionPage from "./pages/ExtensionPage/ExtensionPage"
import ExtensionThanksPage from "./pages/ExtensionThanksPage/ExtensionThanksPage"
import ReactGA from "react-ga4";

function App() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  } else {
    ReactGA.initialize("AW-11036553893");
  }

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="*" element={<LandingPage />} />
        <Route path="/terms-of-use/:useExtension" element={<TermsOfUsePage />} />
        <Route path="/terms-of-use" element={<TermsOfUsePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/extension" element={<ExtensionPage />} />
        <Route path="/ex-thank-you" element={<ExtensionThanksPage />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
