import React, {useEffect} from "react"
import TMHBanner from "../../components/TMHBanner/TMHBanner"
import TMHHeader2 from "../../components/TMHHeader/TMHHeader2"
import TMHFooter from "../../components/TMHFooter/TMHFooter"
import "./ExtensionThanksPage.scss"
import TickImg from "./tick.png"
import { useTitle } from 'react-use'
import ReactGA from "react-ga4";
var isFirstLoad = true

export default function ExtensionThanksPage() {
    useTitle("Thank You - My Movie Remote")

    useEffect(() => {
        if (isFirstLoad) {
            isFirstLoad = false
            ReactGA.event("conversion", {"send_to": "AW-11036553893/HXbSCIz0yNoYEKXl0Y4p"})
        }
    }, [])

    return (
        <>
            <TMHBanner />
            <div className="extension-thanks-container">
                <TMHHeader2 />
                <div className="label-1"> Thanks for installing the Roku Movie Remote Chrome Extension! </div>
                <div className="label-1">  To keep using our remote ensure you click </div>
                <div className="label-2-container">
                    <span className="label-2">"KEEP IT"</span>
                    <img className="tick-img" src={TickImg} alt="tick" />
                </div>
                <div className="label-1">When prompted</div>

                <button className="get-button" id="ext-open-button">
                    Ok, open it now!
                </button>
                <TMHFooter mobile={false} />
            </div>
        </>
    )
}