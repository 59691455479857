import React from "react"
import TMHBanner from "../../components/TMHBanner/TMHBanner"
import TMHHeader from "../../components/TMHHeader/TMHHeader"
import TMHFooter from "../../components/TMHFooter/TMHFooter"
import "./PrivacyPolicyPage.scss"
import { useTitle } from 'react-use'
import PolicesData from "./data.json"
import NextIcon from "./indent-icon.png"

export default function PrivacyPolicyPage() {
    useTitle("Privacy Policy - My Movie Remote")

    return (
        <>
            <TMHBanner />
            <div className="privacy-policy-container">
                <TMHHeader />
                <label className="title-label">{PolicesData.title}</label>
                <label className="sub-title-label">{PolicesData.subTitle}</label>
                <div className="page-content">
                    <div className="desc">
                        {PolicesData.description.map((item, index) => (
                            <p key={`policy-description-${index}`}>{item}</p>
                        ))}
                    </div>
                    <div className="breakLine"></div>
                    {PolicesData.policy.map((policy, index) => (
                        <div key={`policy-item-${index}`}>
                            <p className="question"><b>{policy.question}</b></p>
                            {policy.answer.map((answer, index) => {
                                if (typeof (answer) === "object") {
                                    return (answer.map((bullet, index) => (
                                        <div className="bulletItem" key={`policy-bullet-${index}`}>
                                            <img src={NextIcon} alt="next-icon" />
                                            <p dangerouslySetInnerHTML={{ __html: bullet }}></p>
                                        </div>
                                    ))
                                    )
                                }
                                else {
                                    return <p key={`policy-answer-${index}`} dangerouslySetInnerHTML={{ __html: answer }}></p>
                                }
                            })}

                            <div className="breakLine" />
                        </div>
                    ))}
                    <p className="question"><b>Questions?</b></p>
                    <p>If you have any questions or comments about this Privacy Policy, contact us through the <a style={{ color: "white" }} href="mailto:contact@mymovieremote.com">contact@mymovieremote.com</a>.</p>
                </div>
                <TMHFooter />
            </div>
        </>
    )
}