import "./TMHBannerStyles.scss";
import Popup from 'reactjs-popup'
import getUserLocationFromAPI from "../../apiService"
import React, { useEffect, useState } from "react";

export default function TMHBanner({children}) {
    const [openPopup, setOpenPopup] = useState(false)

    useEffect(() => {
        const initData = async () => {
            var locationData = await getUserLocationFromAPI()
            if (locationData.toUpperCase() === "US") {

            } else {
                setOpenPopup(true)
            }
        }

        initData()
    }, [])
    return (
        <div className="wrapper-banner">
            {children}
            <Popup
                onClose={() => {
                    window.open("about:blank", "_self");
                    window.close();
                }}
                modal
                open={openPopup}
                overlayStyle={{
                    "backgroundColor": "#000000B2"
                }}
            >
                <div className="modal-nonus">
                    <div className="content">
                        <div className="title"><b>Apologies....</b></div>
                        <div className="description">
                            We currently only support customers inside the USA. We continue to expand our services so please check back in the future
                        </div>

                        <button className="close-button" onClick={() => {
                            window.open("about:blank", "_self");
                            window.close();

                        }}>Close window</button>
                    </div>
                </div>
            </Popup>
        </div>
    )
}