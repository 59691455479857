import React from "react"
import "./TMHHeaderStyles.scss"
import LogoImg from "./logo.png"

export default function TMHHeader2() {
    return (
        <a href="/" className="tmh-header">
            <label className="header-title"><b>Thank you!</b></label>
            <img className="header-logo" src={LogoImg} alt="Roku Remote logo" />
        </a>
    )
}