import React from "react"
import TMHBanner from "../../components/TMHBanner/TMHBanner"
import TMHHeader from "../../components/TMHHeader/TMHHeader"
import TMHFooter from "../../components/TMHFooter/TMHFooter"
import "./LandingPage.scss"
import GooglePlayIcon from "./google-play.png"
import AppStoreIcon from "./app-store.png"
import RemoteIcon from "./remote.png"
import { useTitle } from 'react-use'
import { useNavigate } from "react-router-dom";

export default function LandingPage() {
    const navigate = useNavigate()
    useTitle("Home Page - My Movie Remote")

    return (
        <>
            <TMHBanner />
            <div className="landing-container">
                <TMHHeader />
                <label className="label-1">
                    Roku Movie Remote is simply the best FREE mobile remote for Roku while also allowing users free access to New Release Movies. Use the Roku Movie Remote as your primary or secondary remote. <br /> <br />
                    Sync and set up takes less than a minute. On install you'll connect your phone or tablet to the same wireless network as your Roku so you can use the mobile app remote features.
                </label>
                <label className="label-2">Download for mobile</label>
                <div className="platform-block">
                    <a href="https://play.google.com/store/apps/details?id=com.roku.movieremote" target="_blank" rel="noreferrer">
                        <button className="platform-logo" >
                            <img src={GooglePlayIcon} alt="gg-icon" />
                        </button>
                    </a>
                    <a href="https://apps.apple.com/ky/app/roku-movie-remote/id6447076515" target="_blank" rel="noreferrer">
                        <button className="platform-logo" >
                            <img src={AppStoreIcon} alt="app-store-icon" />
                        </button>
                    </a>
                </div>
                <label className="label-2">Find out more about our browser extension</label>
                <div className="roku-remote-block" onClick={() => navigate("/extension")} >
                    <img onClick={() => navigate("/extension")} className="logo" src={RemoteIcon} alt="remote-icon" />
                    <label onClick={() => navigate("/extension")} className="label-3">View the Roku Movie Remote Browser Extension</label>
                </div>
                <TMHFooter />
            </div>
        </>
    )
}